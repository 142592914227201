import { curry } from "ramda";
import { isValidValue } from "utils/validation";
import { quarter, halfyear, tertials, statisticPeriodInMonths } from "./formatPeriods";
var one = function one() {
  return 1;
};
var add = function add(x) {
  return x.getUTCMonth() + 1;
};
var modifier = {
  month: add,
  quarter: quarter,
  halfyear: halfyear,
  tertials: tertials,
  year: one,
  twoyear: one,
  threeyear: one,
  fouryear: one,
  fiveyear: one,
  sixyear: one,
  sevenyear: one,
  eightyear: one,
  nineyear: one,
  tenyear: one,
  none: function none(x) {
    return x;
  }
};
export var samePeriod = function samePeriod(x, y) {
  var _x$start, _y$start, _x$end, _y$end;
  return y && x ? ((_x$start = x.start) === null || _x$start === void 0 ? void 0 : _x$start.getTime()) === ((_y$start = y.start) === null || _y$start === void 0 ? void 0 : _y$start.getTime()) && ((_x$end = x.end) === null || _x$end === void 0 ? void 0 : _x$end.getTime()) === ((_y$end = y.end) === null || _y$end === void 0 ? void 0 : _y$end.getTime()) : false;
};
export var periodStart = function periodStart(periodType, date) {
  var dateFunc = modifier[(periodType === null || periodType === void 0 ? void 0 : periodType.toLowerCase()) || "none"];
  var year = date.getUTCFullYear();
  var months = statisticPeriodInMonths(periodType);
  var month = dateFunc(date) * months - months;
  return new Date(Date.UTC(year, month, 1));
};
export var periodEnd = function periodEnd(periodType, date) {
  var dateFunc = modifier[(periodType === null || periodType === void 0 ? void 0 : periodType.toLowerCase()) || "none"];
  var year = date.getUTCFullYear();
  var months = statisticPeriodInMonths(periodType);
  var month = dateFunc(date) * months;
  return new Date(Date.UTC(year, month, 0));
};
export var getPeriod = function getPeriod(periodType, date) {
  if (!periodType || !date || isNaN(date) || !modifier[periodType === null || periodType === void 0 ? void 0 : periodType.toLowerCase()]) return null;
  return {
    start: periodStart(periodType, date),
    end: periodEnd(periodType, date)
  };
};
export var monthsBetweenDates = function monthsBetweenDates(dateFrom, dateTo) {
  if (!isValidValue(dateTo) || !isValidValue(dateFrom)) return null;
  return dateTo.getUTCMonth() - dateFrom.getUTCMonth() + 12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear());
};
export var periodsBetween = curry(function (periodType, dateFrom, dateTo) {
  if (!isValidValue(periodType) || !isValidValue(dateFrom) || !isValidValue(dateTo)) return null;
  return Math.floor(monthsBetweenDates(dateFrom, dateTo) / statisticPeriodInMonths(periodType));
});
export var nextPeriod = curry(function (periodType, period) {
  if (!periodType || !period) return null;
  switch (periodType === null || periodType === void 0 ? void 0 : periodType.toLowerCase()) {
    case "month":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth() + 2, 0)));
    case "quarter":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth() + 4, 0)));
    case "halfyear":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth() + 7, 0)));
    case "tertials":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth() + 5, 0)));
    default:
      //Year, TwoYear, ThreeYear, FourYear, FiveYear, SixYear, SevenYear, EightYear, NineYear, TenYear
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear() + 1, period.start.getUTCMonth(), period.start.getUTCDate())));
  }
});
export var previousPeriod = curry(function (periodType, period) {
  if (!periodType || !period) return null;
  switch (periodType === null || periodType === void 0 ? void 0 : periodType.toLowerCase()) {
    case "month":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth(), 0)));
    case "quarter":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth() - 2, 0)));
    case "halfyear":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth() - 5, 0)));
    case "tertials":
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear(), period.start.getUTCMonth() - 3, 0)));
    default:
      //Year, TwoYear, ThreeYear, FourYear, FiveYear, SixYear, SevenYear, EightYear, NineYear, TenYear
      return getPeriod(periodType, new Date(Date.UTC(period.start.getUTCFullYear() - 1, period.start.getUTCMonth(), period.start.getUTCDate())));
  }
});
export var nthPreviousPeriod = curry(function (periodType, period, times) {
  return Array.from({
    length: times
  }).reduce(function (acc) {
    acc = previousPeriod(periodType, acc);
    return acc;
  }, period);
});
export var collectPeriodsBetween = function collectPeriodsBetween(periodType, periodFrom, periodTo) {
  var acc = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  if (!isValidValue(periodType) || !isValidValue(periodFrom) || !isValidValue(periodTo)) return acc;
  acc.push(periodFrom);
  return !samePeriod(periodFrom, periodTo) ? collectPeriodsBetween(periodType, nextPeriod(periodType, periodFrom), periodTo, acc) : acc;
};