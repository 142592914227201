import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { curry, pluck, reduce, maxBy } from "ramda";
import { samePeriod, getPeriod, previousPeriod } from "utils/periods";
import { valueBearing, isEmptyArray } from "utils/validation";
export var NO_MEASUREMENT_LABEL = "-";
var byPeriodMax = function byPeriodMax(x) {
  var _x$period, _x$period$end;
  return (x === null || x === void 0 ? void 0 : (_x$period = x.period) === null || _x$period === void 0 ? void 0 : (_x$period$end = _x$period.end) === null || _x$period$end === void 0 ? void 0 : _x$period$end.getTime()) || -Infinity;
};
export var measurementByPeriod = curry(function (period, measurements) {
  return Array.isArray(measurements) ? measurements.find(function (measurement) {
    return samePeriod(period, measurement === null || measurement === void 0 ? void 0 : measurement.period);
  }) : undefined;
});
export var extractValue = curry(function (defaultWhenNotValueBearing, measurement) {
  if (!measurement) return defaultWhenNotValueBearing;
  switch (measurement.type) {
    case "ProportionMeasure":
    case "ContinuousVariableMeasure":
    case "CohortMeasure":
      return valueBearing(measurement.value) ? measurement.value : defaultWhenNotValueBearing;
    case "MissingMeasure":
      return defaultWhenNotValueBearing === "-" ? measurement.reasonCode : defaultWhenNotValueBearing;
    default:
      return defaultWhenNotValueBearing;
  }
});
export var getLatestMeasurement = function getLatestMeasurement(measurements) {
  return reduce(maxBy(byPeriodMax), null, measurements);
};
export var getPreviousMeasurement = function getPreviousMeasurement(measurements, measurement) {
  if (isEmptyArray(measurements) || !measurement) return null;
  var _previousPeriod = previousPeriod(measurement.periodType, getPeriod(measurement.periodType, measurement.period.start));
  return measurementByPeriod(_previousPeriod, measurements);
};
export var extractMeasurementProp = {
  confidenceInterval: function confidenceInterval(measurement) {
    var _measurement$confiden, _measurement$confiden2;
    if (!valueBearing(measurement === null || measurement === void 0 ? void 0 : (_measurement$confiden = measurement.confidenceInterval) === null || _measurement$confiden === void 0 ? void 0 : _measurement$confiden.low) || !valueBearing(measurement === null || measurement === void 0 ? void 0 : (_measurement$confiden2 = measurement.confidenceInterval) === null || _measurement$confiden2 === void 0 ? void 0 : _measurement$confiden2.high)) {
      return NO_MEASUREMENT_LABEL;
    }
    return "".concat(measurement.confidenceInterval.low, " - ").concat(measurement.confidenceInterval.high);
  },
  denominator: function denominator(measurement) {
    return !valueBearing(measurement === null || measurement === void 0 ? void 0 : measurement.denominator) ? NO_MEASUREMENT_LABEL : measurement.denominator;
  },
  numerator: function numerator(measurement) {
    return !valueBearing(measurement === null || measurement === void 0 ? void 0 : measurement.numerator) ? NO_MEASUREMENT_LABEL : measurement.numerator;
  }
};
export var getRelativeTo = curry(function (defaultValue, relativeTo, latest) {
  var _latest$relativeTo, _latest$relativeTo2;
  var previous = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var soughtValue = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  if (!latest || !soughtValue || soughtValue === "Ej angivet") return defaultValue;
  var latestValue = extractValue(null, latest);
  var prevValue = extractValue(null, previous);
  var se = (_latest$relativeTo = latest.relativeTo) === null || _latest$relativeTo === void 0 ? void 0 : _latest$relativeTo.se;
  var goalValue = (_latest$relativeTo2 = latest.relativeTo) === null || _latest$relativeTo2 === void 0 ? void 0 : _latest$relativeTo2.goalValue;
  var factor = soughtValue === "Lågt" ? -1 : 1;
  switch (relativeTo) {
    case "se":
      if (!se && se !== 0) return defaultValue;
      return se === 0 || se > 0 ? 1 * factor : -1 * factor;
    case "goalValue":
      if (!goalValue && goalValue !== 0) return defaultValue;
      return goalValue === 0 || goalValue > 0 ? 1 * factor : -1 * factor;
    case "previous":
      if (!valueBearing(prevValue) || !valueBearing(latestValue)) return defaultValue;
      return latestValue >= prevValue ? 1 * factor : -1 * factor;
    default:
      return defaultValue;
  }
});

/*Translates relativeTo based on soughtValue.
  If a value is negative/smaller than it's compared value and soughtValue is low then it's flipped into a positive value.*/
export var getTranslatedRelativeToValue = curry(function (defaultValue, relativeTo, latest, soughtValue) {
  var _latest$relativeTo3, _latest$relativeTo4, _latest$relativeTo5, _latest$relativeTo6, _latest$relativeTo7, _latest$relativeTo8;
  if (!latest || !soughtValue || soughtValue === "Ej angivet") return defaultValue;
  var latestValue = extractValue(null, latest);
  var factor = soughtValue === "Lågt" ? -1 : 1;
  switch (relativeTo) {
    case "se":
      if (!((_latest$relativeTo3 = latest.relativeTo) !== null && _latest$relativeTo3 !== void 0 && _latest$relativeTo3.se) && ((_latest$relativeTo4 = latest.relativeTo) === null || _latest$relativeTo4 === void 0 ? void 0 : _latest$relativeTo4.se) !== 0) return defaultValue;
      return ((_latest$relativeTo5 = latest.relativeTo) === null || _latest$relativeTo5 === void 0 ? void 0 : _latest$relativeTo5.se) * factor;
    case "goalValue":
      if (!((_latest$relativeTo6 = latest.relativeTo) !== null && _latest$relativeTo6 !== void 0 && _latest$relativeTo6.goalValue) && ((_latest$relativeTo7 = latest.relativeTo) === null || _latest$relativeTo7 === void 0 ? void 0 : _latest$relativeTo7.goalValue) !== 0) return defaultValue;
      return ((_latest$relativeTo8 = latest.relativeTo) === null || _latest$relativeTo8 === void 0 ? void 0 : _latest$relativeTo8.goalValue) * factor;
    case "previous":
      if (!valueBearing(latestValue)) return defaultValue;
      return latestValue;
    default:
      return defaultValue;
  }
});

/*return relativeTo value as is, return defaultvalue when relativeTo doesn't exist*/
export var getRelativeToValue = curry(function (defaultValue, relativeTo, latest) {
  var _latest$relativeTo9, _latest$relativeTo10, _latest$relativeTo11, _latest$relativeTo12;
  if (!latest) return defaultValue;
  var latestValue = extractValue(null, latest);
  var ratio = Math.round(100 * (latest.value / (latest.value - latest.relativeTo.se) - 1) * 10) / 10;
  switch (relativeTo) {
    case "se":
      if (!((_latest$relativeTo9 = latest.relativeTo) !== null && _latest$relativeTo9 !== void 0 && _latest$relativeTo9.se) && ((_latest$relativeTo10 = latest.relativeTo) === null || _latest$relativeTo10 === void 0 ? void 0 : _latest$relativeTo10.se) !== 0) return defaultValue;
      return ratio;
    case "goalValue":
      if (!((_latest$relativeTo11 = latest.relativeTo) !== null && _latest$relativeTo11 !== void 0 && _latest$relativeTo11.goalValue) && ((_latest$relativeTo12 = latest.relativeTo) === null || _latest$relativeTo12 === void 0 ? void 0 : _latest$relativeTo12.goalValue) !== 0) return defaultValue;
      return latest.relativeTo.goalValue;
    case "previous":
      if (!valueBearing(latestValue)) return defaultValue;
      return latestValue;
    default:
      return defaultValue;
  }
});

/*Function returns difference between measurement value and se/target value.
  If there are multiple targetvalues then soughtvalue decides which value to compare to.*/
export var calculateRelativeToValue = function calculateRelativeToValue(measure, reichMeasurementsWithMetadata, measurement) {
  var _measure$targetMeasur;
  var pluckProp = function pluckProp(targets) {
    return targets[0].hasOwnProperty("Value") ? "Value" : "value";
  };
  var targetValues = (measure === null || measure === void 0 ? void 0 : (_measure$targetMeasur = measure.targetMeasurements) === null || _measure$targetMeasur === void 0 ? void 0 : _measure$targetMeasur.length) > 0 && pluck(pluckProp(measure.targetMeasurements), measure.targetMeasurements);
  var targetToCompare = targetValues ? (measure === null || measure === void 0 ? void 0 : measure.soughtValue) === "Högt" ? Math.max.apply(Math, _toConsumableArray(targetValues)) : Math.min.apply(Math, _toConsumableArray(targetValues)) : null;
  var reichMeasurement = measurementByPeriod(getPeriod(measurement.periodType, measurement.period.end), reichMeasurementsWithMetadata === null || reichMeasurementsWithMetadata === void 0 ? void 0 : reichMeasurementsWithMetadata.measurements);
  var reichValue = extractValue(null, reichMeasurement);
  var measurementValue = extractValue(null, measurement);
  var se = valueBearing(reichValue) && valueBearing(measurementValue) && Math.round((measurementValue - reichValue) * 10) / 10 || null;
  var goalValue = targetToCompare && valueBearing(measurementValue) && Math.round((measurementValue - targetToCompare) * 10) / 10 || null;
  return {
    se: se,
    goalValue: goalValue
  };
};