import { valueBearing } from "utils/validation";
export var comparators = {
  se: "Rikssnitt",
  goalValue: "Målvärde",
  previous: "Föregående värde"
};
export var measureUnitLabel = function measureUnitLabel(measure) {
  var _measure$measureUnit;
  return measure.measureUnit === "%" || ((_measure$measureUnit = measure.measureUnit) === null || _measure$measureUnit === void 0 ? void 0 : _measure$measureUnit.toLowerCase()) === "procent" ? "%" : "";
};
export var relativeToLabel = function relativeToLabel(relativeTo, relativeToValue) {
  return valueBearing(relativeToValue) && (relativeTo !== "previous" ? relativeToValue === 0 ? "V\xE4rdet \xE4r samma som ".concat(comparators[relativeTo]) : relativeToValue > 0 ? "V\xE4rdet \xE4r b\xE4ttre \xE4n ".concat(comparators[relativeTo]) : "V\xE4rdet \xE4r s\xE4mre \xE4n ".concat(comparators[relativeTo]) : "");
};
export var measureCodeExplanations = function measureCodeExplanations(input) {
  var safeInput = typeof input === "string" ? input.toLowerCase() : input;
  switch (safeInput) {
    case "msk":
      return "MASKED - Värdet är maskerat på grund av statistisk osäkerhet eller röjanderisk.";
    case "unk":
      return "UNKNOWN - Enheten har rapporterat att värde saknas";
    case "inv":
      return "INVALID - Nämnaren eller mätpopulationen är 0 och inget värde kan anges";
    case "ni":
      return "NOT INDICATED - Orsaken till att inget värde anges är okänd";
    case "-":
    case null:
      return "MISSING - Inrapporterat värde saknas från enheten denna period";
    default:
      return null;
  }
};
export var ProportionMeasure = [{
  label: "VÄR",
  id: "value"
}, {
  label: "KI",
  id: "confidenceInterval"
}, {
  label: "TÄL",
  id: "numerator"
}, {
  label: "NÄM",
  id: "denominator"
}];
export var ContinuousVariableMeasure = [{
  label: "VÄR",
  id: "value"
}, {
  label: "KI",
  id: "confidenceInterval"
}];
export var CohortMeasure = [{
  label: "VÄR",
  id: "value"
}, {
  label: "KI",
  id: "confidenceInterval"
}];
export var MissingMeasure = [{
  label: "VÄR",
  id: "reasonCode"
}];
export var translateMeasurementProp = {
  VÄR: "Värde",
  KI: "Konfidensintervall",
  TÄL: "Täljare",
  NÄM: "Nämnare"
};
export var measureUtils = {
  ProportionMeasure: ProportionMeasure,
  ContinuousVariableMeasure: ContinuousVariableMeasure,
  CohortMeasure: CohortMeasure,
  MissingMeasure: MissingMeasure,
  translateMeasurementProp: translateMeasurementProp
};